import { HttpService } from '@/shared/api/services/HttpService';
import type { Aufgabe } from '@/types/Aufgabe';
import type {
  AufgabeFilter,
  AufgabeFilterResponse,
} from '@/connection/filter-helper/filter-helper-aufgaben';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';

export class SharedAufgabenService extends HttpService {
  protected aufgabenServiceBaseUrl = `${buildUrlForServiceName(conf.aufgabenserviceName)}/aufgabenservice/api/private/v1`;

  protected aufgabenServiceAufgabenBaseUrl = `${this.aufgabenServiceBaseUrl}/aufgaben`;

  holeAufgabe(id: string): Promise<Aufgabe> {
    return this.httpClient.get(`${this.aufgabenServiceAufgabenBaseUrl}/${id}`);
  }

  holeAufgabenMitPaginierungUndFilterung(
    seite: number,
    seitengroesse: number,
    property: string,
    sortierArt: string,
    aufgabeFilter: AufgabeFilter,
  ): Promise<AufgabeFilterResponse> {
    return this.httpClient.post(`${this.aufgabenServiceAufgabenBaseUrl}/filterung`, {
      params: { page: seite, size: seitengroesse, sort: property, direction: sortierArt },
      data: aufgabeFilter,
    });
  }

  async existiertOffeneTextpruefungsAufgabe(id: string, buergeId: string): Promise<boolean> {
    if (id) {
      return this.httpClient.get(
        `${this.aufgabenServiceBaseUrl}/buergschaftstemplates/${id}/aufgaben`,
        {
          params: { buergeId },
        },
      );
    }
    return null;
  }
}
