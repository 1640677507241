import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import { configForDownload } from '@/connection/base';
import type { FilterOptionen } from '@/components/datatable/types/FilterObject';
import type {
  TemplateFilter,
  TemplateFilterResponse,
} from '@/connection/filter-helper/filter-helper-template';
import type { Templatetyp } from '@/types/Dokument';

export class SharedDokumentService extends HttpService {
  protected dokumentServiceBaseUrl = `${buildUrlForServiceName(conf.dokumentserviceName)}/dokumentservice/api/private/v2`;

  protected dokumentServiceBaseUrlAg = `${buildUrlForServiceName(conf.dokumentserviceName)}/dokumentservice/api/private/v2/auftraggeber`;

  async holeBuergschaftPdf(buergschaftId: number): Promise<BlobPart> {
    return this.httpClient.get(
      `${this.dokumentServiceBaseUrlAg}/buergschaftsdokumente/buergschaft_${buergschaftId}.pdf`,
      { config: configForDownload },
    );
  }

  async holeFilterOptionenTemplate(templatetyp: Templatetyp): Promise<FilterOptionen> {
    return this.httpClient.post(
      `${this.dokumentServiceBaseUrl}/buergschaftstemplates/filteroptionen`,
      {
        data: { templatetyp },
      },
    );
  }

  async holeTemplateMitPaginierungUndFilterung(
    seite: number,
    seitengroesse: number,
    property: string,
    sortierArt: string,
    templateFilter: TemplateFilter,
  ): Promise<TemplateFilterResponse> {
    return this.httpClient.post(`${this.dokumentServiceBaseUrl}/buergschaftstemplates/filterung`, {
      params: { page: seite, size: seitengroesse, sort: `${property},${sortierArt}` },
      data: templateFilter,
    });
  }

  async istBuergschaftstemplateUngeprueft(
    id: string,
    auftraggeberId: string,
    buergeId: string,
    vertragsdatum: string,
  ): Promise<boolean> {
    if (id) {
      return this.httpClient.get(
        `${this.dokumentServiceBaseUrl}/buergschaftstemplates/${id}/ungeprueft`,
        {
          params: { auftraggeberId, buergeId, vertragsdatum },
        },
      );
    }
    return null;
  }

  async sendBuergschaftstemplateVerwendet(id: string) {
    if (id) {
      this.httpClient.get(`${this.dokumentServiceBaseUrl}/buergschaftstemplates/${id}/verwendet`);
    }
  }
}
