import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import type { Kommunikation } from '@/types/Kommunikation';
import { Gevo } from '@/types/Kommunikation';
import type { Unternehmen } from '@/types/Partner';
import type { Buerge } from '@/shared/Buergen/types';
import type {
  UnternehmenFilter,
  UnternehmenFilterResponse,
} from '@/connection/filter-helper/filter-helper-unternehmen';
import type { TabelleServerParams } from '@/components/datatable/types/TabellenServerParams';
import type { PreisModell } from '@/shared/useFeatureToggles/preisModell';

export class SharedPartnerService extends HttpService {
  protected partnerServiceBaseUrl = `${buildUrlForServiceName(
    conf.partnerserviceName,
  )}/partnerservice/api/private/v2`;

  async holeBuergenKommunikation(gevo: Gevo, buergeId: string): Promise<Kommunikation> {
    const gevoString: string = Gevo[gevo];
    return this.httpClient.get(
      `${this.partnerServiceBaseUrl}/buergen/${buergeId}/gevo/${gevoString}`,
    );
  }

  async findeAlleUnternehmenNamenNachId(unternehmenIds: string[]): Promise<Unternehmen[]> {
    return this.httpClient.post(`${this.partnerServiceBaseUrl}/unternehmen/name`, {
      data: unternehmenIds,
    });
  }

  async findeUnternehmenNachIdNoCache(id: string): Promise<Unternehmen> {
    return this.httpClient.get(`${this.partnerServiceBaseUrl}/unternehmen/${id}`, {
      params: {
        cache: false,
      },
    });
  }

  async bearbeiteUnternehmenPreisModell(
    id: string,
    preisModell: keyof typeof PreisModell,
  ): Promise<void> {
    return this.httpClient.post(`${this.partnerServiceBaseUrl}/unternehmen/${id}/preismodell`, {
      data: {
        gueltigAb: new Date(Date.now()),
        preisModell,
      },
    });
  }

  async findeAlleBuergen(): Promise<Buerge[]> {
    return this.httpClient.get(`${this.partnerServiceBaseUrl}/buergen`);
  }

  async holeUnternehmenMitPaginierungUndFilterungUndSichtberechtigung(
    unternehmenFilter: UnternehmenFilter,
    serverParams: TabelleServerParams,
    filtereInaktive?: boolean,
    filtereInkognito?: boolean,
    filtereSichtberechtigung?: boolean,
    auchFremdeUnternehmen?: boolean,
    filtereUngepruefte?: boolean,
  ): Promise<UnternehmenFilterResponse> {
    return this.httpClient.post(`${this.partnerServiceBaseUrl}/unternehmen/filterung?`, {
      data: unternehmenFilter,
      params: {
        page: serverParams.page,
        size: serverParams.perPage,
        sort: `${serverParams.sort.field},${serverParams.sort.type}`,
        filtereInaktive,
        filtereInkognito,
        filtereSichtberechtigung,
        auchFremdeUnternehmen,
        filtereUngepruefte,
      },
    });
  }

  async holeUnternehmenMitId(id: string | number): Promise<Unternehmen> {
    return this.httpClient.get(`${this.partnerServiceBaseUrl}/unternehmen/${id}`);
  }
}
