<script setup lang="ts">
import { computed } from 'vue';

import type { TBadgeVarianten } from '@/design-system/atoms/TBadge/types';
import TBadge from '@/design-system/atoms/TBadge/TBadge.vue';
import TButton from '@/design-system/atoms/TButton/TButton.vue';
import { TButtonSize, TButtonType } from '@/design-system/atoms/TButton/types';
import TIcon from '@/design-system/atoms/TIcon/TIcon.vue';
import { TIconSize } from '@/design-system/atoms/TIcon/types';
import { TRibbonVariant } from '@/design-system/atoms/TRibbon/types';

export interface Props {
  variant?: TRibbonVariant;
  buttonText?: string;
  buttonTarget?: string;
  badge?: TBadgeVarianten;
  closable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: () => TRibbonVariant.NEWS,
  buttonText: undefined,
  buttonTarget: undefined,
  badge: undefined,
  closable: false,
});
const emit = defineEmits<{ close: [void] }>();

const variantenClass = computed(() => `t-ribbon--${props.variant}`);
</script>

<template>
  <div class="t-ribbon" :class="variantenClass">
    <div class="t-ribbon--content">
      <TBadge v-if="badge" :variante="badge" class="mr-2" />

      <div class="t-ribbon--content--slot-content">
        <slot />
      </div>

      <a
        v-if="buttonText && buttonTarget"
        :href="buttonTarget"
        rel="noopener noreferrer"
        target="_blank"
        class="ml-4"
      >
        <TButton :text="buttonText" :type="TButtonType.SECONDARY" :size="TButtonSize.SMALL" />
      </a>
    </div>

    <button v-if="closable" type="button" class="t-ribbon--close-icon" @click="emit('close')">
      <TIcon icon="kreuz" :size="TIconSize.SMALL" />
    </button>
  </div>
</template>

<style></style>
