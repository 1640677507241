import { defineStore } from 'pinia';
import type { Buerge } from '@/shared/Buergen/types';
import { useNutzerStore } from '@/shared/Nutzer/useNutzerStore';

interface StoreState {
  buergen: Map<string, Buerge>;
}

export const useBuergenStore = defineStore('BürgenStore', {
  state: (): StoreState => ({
    buergen: new Map<string, Buerge>(),
  }),

  actions: {
    fuegeBuergeHinzu(buerge: Buerge) {
      if (!this.buergen.has(buerge.unternehmenId)) {
        this.buergen.set(buerge.unternehmenId, buerge);
      }
    },
    ersetzeBuerge(buerge: Buerge) {
      this.buergen.set(buerge.unternehmenId, buerge);
    },
  },

  getters: {
    getBuergen(state): Buerge[] {
      return Array.from(state.buergen.values());
    },

    getBuergeById(state): (id: string) => Buerge | undefined {
      return (id: string): Buerge | undefined => state.buergen.get(id);
    },

    getBuergeName(state): (id: string) => string {
      return (id: string): string => state.buergen.get(id).name;
    },
    isLieferkanalPdf(state): boolean {
      const nutzerStore = useNutzerStore();
      return !!state.buergen.get(nutzerStore.nutzer.organisation).lieferkanalPdf;
    },
  },
});
