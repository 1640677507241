import { createI18n } from 'vue-i18n';

import type { LocaleSchema } from '@/lang/types';
import deDe from './langFiles/de-DE';

// Typ der genutzten Locales im i18n Setup
type Locales = 'de-DE';

const i18n = createI18n<[LocaleSchema], Locales>({
  locale: 'de-DE',
  fallbackLocale: 'de-DE',
  legacy: false,
  messages: {
    'de-DE': deDe,
  },
});

export default i18n;
